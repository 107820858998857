import React from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"
import { useInstagram } from "../../../hooks/useInstagram"
import { useRoutes } from "../../../hooks/useRoutes"

export const withInstagram =
  Component =>
  ({ name = "Instagram", title, link: originalLink, analytics = {} }) => {
    const { trackPromoImpression, VisibilitySensor } = useAnalytics()
    const { items } = useInstagram()
    const { linkResolver } = useRoutes()

    const link = linkResolver(originalLink)

    Component.displayName = name
    return (
      <VisibilitySensor
        onChange={visible => visible && trackPromoImpression({ ...analytics })}
      >
        <Component items={items} title={title} link={link} />
      </VisibilitySensor>
    )
  }
