import { useStaticQuery, graphql } from "gatsby"

import { useShopify } from "./useShopify"
import { useImage } from "./useImage"
import { useApp } from "./useApp"

export const useInstagram = () => {
  const {
    config: {
      services: {
        instagram: { url },
      },
    },
  } = useApp()
  const { edgeNormaliser } = useShopify()
  const { getGatsbyImage } = useImage()

  const { images } = useStaticQuery(graphql`
    query INSTGRAM_FEED {
      images: allSanityInstagram(
        filter: { settingsHide: { ne: true } }
        limit: 6
        sort: { fields: _createdAt, order: DESC }
      ) {
        edges {
          node {
            ...GatsbyInstagramFragment
          }
        }
      }
    }
  `)

  const items = edgeNormaliser(images).map(item => ({
    ...item,
    link: {
      title: item.title,
      external: true,
      url: `${url}/${item.shortcode}`,
      isExternal: true,
    },
    image: getGatsbyImage(
      { ...item.image, alt: item.title },
      { maxWidth: 300 },
    ),
  }))

  return {
    items,
  }
}
